<template>
  <div class="lesson-app">
    <div class="center-text">
    <text class="slogan">{{ zhiwangen }}</text>
    <!-- <a class="downapp" href="https://share.zhiwangen.com/static/zhiwangEn.apk" @click="showTooltip"> -->
      <!-- <img src="/static/downapp.png" alt="Download App" id="downloadIcon"> -->
      <!-- <span class="downloadIcon"></span> -->
    <!-- </a> npm run serve / build-->
<!-- 使用 v-if 指令来根据 isInWechatMiniProgram 的值控制下载图标的显示 -->
    <a class="downapp" href="https://urlbase.zhiwangjiaoyu.com/zhiwangen/apk/zhiwang.apk" @click="showTooltip" v-if="!isInWechatMiniProgram">
      <span class="downloadIcon"></span>
    </a>

  </div>

    <div class="header">
      <img v-if="selectedLesson" :src="baseUrl + selectedLesson.text_pic" alt="Selected Lesson Picture" class="lesson-image">
    </div>
    
    <div class="sharesuject">{{ this.selectedLesson && this.selectedLesson.subject ? this.selectedLesson.subject : 'Loading...' }}</div>

   <div class="content-scroll">
    <scroll-view class="content-box" scroll-y="true" :scroll-into-view="currentViewId">
      <div class="sharecontent" v-for="(lesson, index) in lessons" :key="index" :id="'lesson-' + index" :class="{'current-lesson': index === currentSegmentIndex}" @click="selectSegment(index)">
        {{ lesson.content }}
      </div>
    </scroll-view>
  </div>

    <div class="navigation">
      <img src="/static/userface.png" alt="User" class="user-icon" />
      <span v-if="isshow === 1" class="user-name">{{ userName }}</span>
      <button @click="prevSegment">←</button>
      <span>{{ currentSegmentIndex + 1 }} / {{ totalSegments }}</span>      
      <button @click="nextSegment">→</button>
      
      <span class="scorecss" v-if="scoreAve">{{ scoreEva }} / {{ scoreAve }} {{ scoreAve >= 0 ? '分' : '' }}</span>

      
      <button @click="togglePlay" class="play-button">
        <img :src="isPlaying ? '/static/playing.gif' : '/static/play.png'" alt="Play" />
      </button>
    </div>

  </div>
  <!-- 模态对话框 -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showModal = false">&times;</span>
        <p>{{ modalMessage }}</p>
      </div>
    </div>

</template>

<script>
export default {
  data() {
    return {
      baseUrl:'https://www.zhiwangjiaoyu.com/',
      playMode: 'single', // 默认为单段播放
      selectedLesson: null,
      zhiwangen: '智网英语-做有智慧的学习者！',
      isPlaying: false,
      currentViewId: '',
      lessons: [],
      currentSegmentIndex: 0,
      scoreEva:0,//单句得分
      scoreAve:0,//总评分
      audio: new Audio(),
      userName:'',
      isshow:0,
      // isshowTooltip: false,
      tooltipMessage: '请点击右上角●●●图标，在菜单中选择“在浏览器打开”下载应用。',
      isInWechatMiniProgram: /miniProgram/i.test(navigator.userAgent),  //判断是否微信小程序
    };
  },
  computed: {
    totalSegments() {
      return this.lessons.length;
    },
  },
  async mounted() {
    await this.fetchData();
    this.audio.addEventListener('ended', this.handleAudioEnd);
  },
  beforeUnmount() {
    this.audio.removeEventListener('ended', this.handleAudioEnd);
  },
  methods: {
    showTooltip() {
      console.log("downapp");
      const isWechatBrowser = /MicroMessenger/i.test(navigator.userAgent);
      if (isWechatBrowser) {
        window.alert(this.tooltipMessage);
      }  
    },
    //模态提示窗
    showModalError(message) {
      this.modalMessage = message;
      this.showModal = true;
    },
    async fetchData() {
      // 获取URL中的UUID
      const queryParams = new URLSearchParams(window.location.search);
      const upUUID = queryParams.get('upUUID'); // 假设URL是 "...?upUUID=1708139015358"

      if (!upUUID) {
        //alert('无该分享链接，请重试。');
        this.showModalError('无该分享链接，请重试。'); // 使用模态对话框显示错误信息
        return;
      }

      try {
        //const response = await fetch(`https://chat.morecale.com/zhiwangen/getshare.php?upUUID=${upUUID}`);
        const response = await fetch(`https://urlbase.zhiwangjiaoyu.com/zhiwangen/getshare.php?upUUID=${upUUID}`);
        const result = await response.json();
        if (result.status === 'success') {
          // 将数据按照 lessonID 升序排列
          //result.data.sort((a, b) => a.lessonID - b.lessonID);
          this.lessons = result.data;
          this.selectedLesson = this.lessons[0];
          this.userName = this.selectedLesson.userName; // 从选定的课程获取 userName
          this.isshow = this.selectedLesson.isshowname; // 从选定的课程获取 isshowname
          this.scoreEva = this.selectedLesson.scoreEva;
          this.scoreAve = this.selectedLesson.scoreAve;
        } else {
          console.error('数据加载失败');
          alert('无该分享链接，请重试。'); // 如果后端返回的状态不是'success'
        }
      } catch (error) {
          console.error('请求错误', error);
          //alert('无该分享链接，请重试。'); // 网络请求失败的错误处理
          this.showModalError('无该分享链接，请重试。'); // 使用模态对话框显示错误信息
      }
    },

    playSegmentAudio(index) {
      const lesson = this.lessons[index];
      const audioPath = `https://www.zhiwangjiaoyu.com/zhiwangen/${lesson.recordedaudiopath}`;
      this.audio.src = audioPath;
      this.isPlaying = true;
      this.audio.play().then(() => {
        // 播放成功后的逻辑
        this.currentViewId = `lesson-${index}`; // 更新滚动视图的目标ID
      }).catch(e => {
        console.error("Audio play failed:", e);
        this.isPlaying = false;
      });
      this.selectedLesson = lesson;
      this.currentSegmentIndex = index;
      //console.log("Updated currentSegmentIndex:", this.currentSegmentIndex);
      this.scoreEva = this.selectedLesson.scoreEva; // 更新 scoreEva
      this.scoreAve = this.selectedLesson.scoreAve; // 更新 scoreEva


      
      // 更新音频结束后的行为 这个代码导致连续播放时图片不能切换到动态
      // this.audio.onended = () => {
      //   if (this.playMode === 'continuous' && this.currentSegmentIndex < this.lessons.length - 1) {
      //     //this.playSegmentAudio(this.currentSegmentIndex + 1);
      //     this.playSegmentAudio(this.currentSegmentIndex); //在调用后有 +1。          
      //   } else {
      //     this.isPlaying = false;
      //   }
      // };
},

  selectSegment(index) {
    this.playMode = 'single'; // 设置为单段播放模式
    this.playSegmentAudio(index);
  },

  togglePlay() {
    if (this.isPlaying) {
      this.audio.pause();
      this.isPlaying = false;
    } else {
      this.playMode = 'continuous'; // 设置为连续播放模式
      this.playSegmentAudio(this.currentSegmentIndex);
    }
  },

   
  handleAudioEnd() {
  if (this.playMode === 'continuous') {
    // 如果是连续播放模式，则尝试播放下一段
    if (this.currentSegmentIndex < this.lessons.length - 1) {
      //this.playSegmentAudio(this.currentSegmentIndex + 1);

      this.currentSegmentIndex++;
      this.playSegmentAudio(this.currentSegmentIndex);
      this.selectedLesson = this.lessons[this.currentSegmentIndex]; // 确保更新 selectedLesson
      this.scoreEva = this.selectedLesson.scoreEva; // 更新 scoreEva
      this.scoreAve = this.selectedLesson.scoreAve; // 更新 scoreEva

    } else {
      this.isPlaying = false; // 已经到达列表末尾，停止播放
    }
  } else {
    // 如果是单段播放模式，直接停止播放
    this.isPlaying = false;
  }
},

    prevSegment() {
      if (this.currentSegmentIndex > 0) {
        this.playSegmentAudio(this.currentSegmentIndex - 1);
        this.scoreEva = this.selectedLesson.scoreEva; // 更新 scoreEva
        this.scoreAve = this.selectedLesson.scoreAve; // 更新 scoreEva
      }
    },
    nextSegment() {
      if (this.currentSegmentIndex < this.lessons.length - 1) {
        this.playSegmentAudio(this.currentSegmentIndex + 1);
        this.scoreEva = this.selectedLesson.scoreEva; // 更新 scoreEva
        this.scoreAve = this.selectedLesson.scoreAve; // 更新 scoreEva

      }
    },
    
  },
};
</script>

<style>
.content-scroll{
  padding-bottom: 60px;
}
/* 新增样式 */
.navigation .user-name {
  margin-left: -5px; /* 根据需要调整左边距 */
}
.content-box {
  max-width: 90%; /* 确保内容盒子不超出屏幕宽度 */
  margin: 20rpx auto; /* 上下保持20rpx边距，左右自动居中 */
  padding: 20rpx 30rpx;
  line-height: 1.5;
  /* background-color: #fff; */
  border-radius: 10rpx;
  box-shadow: 0 2rpx 4rpx rgba(0, 0, 0, 0.1);
  max-height: 46vh; /* 或者根据您的页面布局计算出的固定值 */
  overflow-y: auto; /* 确保内容超出时可以垂直滚动 */
    /* 其他样式保持不变 */	
  padding-bottom: 80rpx; /* 根据底部导航栏的实际高度调整 */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50rpx; /* 调整高度 */
  padding: 10rpx 10rpx 0rpx 10rpx; /* 增加顶部和底部的padding */
}

.nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15rpx; /* 增加边距 */
  color:#666;
}

.icon {
  font-size: 35rpx; /* 增大图标的大小 */
  padding:10rpx;  
  padding-top:10rpx;
  color:#666;
  
}
.lesson-imageBAK {
  display: block; /* 确保图片不会和其他元素在同一行 */
  max-width: 96%; /* 设置图片最大宽度为容器的90%，以留出边距 */
  margin: 0 auto; /* 上下边距为0，左右自动，使图片居中显示 */
}
.lesson-image {
  display: block; /* 确保图片不会和其他元素在同一行 */
  max-width: 96%; /* 设置图片最大宽度为容器的96%，以留出边距 */
  max-height: 440rpx; /* 设置固定高度，您可以根据需要调整 */
  object-fit: cover; /* 裁剪图片以适应容器，保持图片的宽高比 */
  margin: 0 auto; /* 上下边距为0，左右自动，使图片居中显示 */
}


.navigation button, .navigation span {
  flex: none; /* 防止按钮和文本被伸缩 */
}

.play-button img {
  
  width: 50px; /* 播放按钮图片的宽度，根据需要调整 */
  height: auto; /* 高度自动调整以保持图片比例 */
}
.play-button {
  margin-right:10px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  flex: none; /* 防止Flexbox伸缩调整大小 */
  border: 0px solid #ccc; 
  /* border: 1px solid transparent; 将边框样式修改为实线，颜色为透明 */

  /* 添加边框，根据需要调整样式 */
  border-radius: 50%; 
  /* 为按钮添加圆形边框 */
  width: 40px; /* 按钮宽度，根据需要和图标大小调整 */
  height: 40px; /* 按钮高度，确保和宽度一致以形成圆形 */
  margin-left: auto; /* 推到左边 */
  margin-right:15px;
}

.navigation {  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* 根据需要调整按钮之间的间隔 */
  position: fixed; /* 使用固定定位 */
  left: 0;
  right: 0;
  bottom: 0; /* 定位到页面底部 */
  background-color: #fff; /* 背景色，根据需要调整 */
  padding: 5px 0; /* 增加一些内边距 */
}
.user-icon {
  /* padding:10px; */
  width: 40px; /* 头像的宽度，根据需要调整 */
  height: 40px; /* 头像的高度，确保和宽度一致以形成圆形 */
  border-radius: 50%; /* 使图片呈现圆形 */
  object-fit: cover; /* 确保图片覆盖整个容器区域，不失真 */
  margin-right: 5px; /* 在头像和下一个元素之间添加一些间距，根据布局需要调整 */
  padding-left:10px;
}
.current-lesson {
  color: #000; /* 当前选中的文本颜色 */
  background-color: #f0f0f0; /* 背景色，可根据需求调整 */
}

/* 父容器样式 */
.view-container {
  display: flex;
  justify-content: center; /* 这将确保所有子元素水平居中 */
  /* align-items: center; */ /* 启用这行代码可以使子元素在容器内垂直居中 */
}

/* 文本样式 */
.subjectcss {
  text-align: center; /* 确保文本内容居中 */
  margin-top: 15rpx; /* 上边距 */
  margin-bottom: 0rpx; /* 下边距 */
  color: #1E90FF;
  font-weight: bold;
}
.center-text {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中（如果需要） */
  margin-top:-40px;
  margin-bottom: 20px;
}

.slogan {
  
  font-size: 30rpx; /* 字号设置 */
  color: #666; /* 浅于黑色的颜色，您可以根据需要调整颜色代码 */
  text-align: center; /* 确保文本在其内部居中 */
  margin:0 auto 0 auto;
  /* font-weight: bold; */
}

view {
  color: #ccc; /* 未选中的文本颜色 */
  margin-bottom: 20rpx; /* 段落之间的间距，可根据需求调整 */
}

/* 增加的一个新的分享图标 */
.share-icon {
  margin:60rpx 20rpx 0rpx 0rpx;
  border-radius: 25px;
  /* background-color: #ff4500; /* 使用醒目的颜色 */ 
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 5px #888888;
}

.share-icon-img {  
  width: 30px;
  height: 30px;
}
.share-icon-back {
  margin:40rpx 0rpx 0rpx 20rpx;
  /* border-radius: 25px; */
  /* background-color: #ff4500; /* 使用醒目的颜色 */ 
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 2px 5px #888888; */
}

.share-icon-img-back {  
  width: 40px;
  height: 40px;
}
.sharecontent{
  text-align: left;
  padding:10px 10px;
}
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.sharesuject{
  padding-top:10px;
  padding-bottom: 10px;
  text-align: center; /* 确保文本内容居中 */
  color: #1E90FF;
  font-weight: bold;
}
.downapp {
    /* display: inline-block;
    text-align: right; */
    
    display: inline-block;
    position: relative;
}

.downloadIcon {
  width: 35px; /* 设置图标宽度 */
  height: 35px; /* 设置图标高度 */
  display: inline-block;
  background-image: url('https://share.zhiwangen.com/static/downapp.png');
  background-size: cover;
  position: absolute;
  top: 50%; /* 将图标垂直居中 */
  right: 10px; /* 调整图标位置 */
  transform: translateY(-50%); /* 垂直居中 */ 
}

.scorecss{
  /* font-size:14px; */
  margin-left: 5px; /* 增加左边距 */

}
.user-name{
  margin-left: 5px; 
  /* 减少左边距 */
}

/* 媒体查询 - 在小屏幕上显示不同的样式 */
@media (max-width: 768px) {
  .lesson-app {
    /* 小屏幕样式 */
  }
  .center-text {
    text-align: center;
  }
  .lesson-image {
    width: 100px;
    height: 100px;
  }
  .content-box {
    max-height: 300px;
    overflow-y: scroll;
  }
  .navigation {
    justify-content: center;
  }
}

/* 通用样式 */
.lesson-app {
  /* 通用样式 */
}

/* 媒体查询 - 在小屏幕上显示不同的样式 */
@media (max-width: 768px) {
  .lesson-app {
    /* 小屏幕样式 */
  }
  .center-text {
    text-align: center;
  }
  .lesson-image {
    width: 100%; /* 图片宽度占满父容器 */
    height: auto; /* 高度自适应 */
  }
  .content-box {
    max-height: 300px;
    overflow-y: scroll;
  }
  .navigation {
    justify-content: center;
  }
}

/* 媒体查询 - 在大屏幕上显示不同的样式 */
@media (min-width: 769px) {
  .lesson-app {
    /* 大屏幕样式 */
  }
  .lesson-image {
    max-width: 50%; /* 图片最大宽度为80% */
    height: auto; /* 高度自适应 */
  }
  /* 其他大屏幕样式 */
}


</style>

